import { init, piniaIntegration, browserTracingIntegration, replayIntegration } from '@sentry/nuxt'

const { public: { sentry, app } } = useRuntimeConfig()

if (sentry.dsn) {
	const router = useRouter()
	init({
		dsn: sentry.dsn,
		environment: sentry.environment,

		release: app.release,
		integrations: [
			piniaIntegration(usePinia()),
			browserTracingIntegration({ router }),
			replayIntegration({
				maskAllText: false
			})
		],

		// Performance Monitoring
		tracesSampleRate: 0.02,
		// Set sampling rate for profiling - this is relative to tracesSampleRate
		profilesSampleRate: 0.1,

		replaysSessionSampleRate: 0.005,
		replaysOnErrorSampleRate: 0.01
	})
}
